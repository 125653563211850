<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">班级培训统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl"
          style="display: flex; flex-direction: column; align-items: flex-start"
        >
          <div class="searchbox">
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel">班级编码:</span>
              <el-input
                v-model="projectCode"
                type="text"
                size="small"
                placeholder="请输入班级编码"
                clearable
              />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="projectName"
                type="text"
                size="small"
                placeholder="请输入班级名称"
                clearable
              />
            </div>
            <div title="单位名称" class="searchboxItem ci-full">
              <span class="itemLabel">单位名称:</span>
              <el-input
                v-model="compName"
                type="text"
                size="small"
                placeholder="请输入单位名称"
                :disabled="copNameState == true"
                clearable
              />
            </div>
          </div>
          <div class="searchbox" style="padding: 1rem 0">
            <!-- <span title="培训类型" class="searchboxItem ci-full flexcc">
              <span class="itemLabel" style="font-size: 14px; padding: 0 0.5rem"
                >培训类型:</span
              >
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                modal
                typeStu
              />
            </span> -->
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
            
              <el-cascader
                v-model="areaId"
                :options="areatreeList"
                clearable
                filterable
                :props="propsarea"
                size="small"
                @change="(val)=>{clean(val,'areaId')}"
              ></el-cascader>
            </div>
            <div title="班级状态" class="searchboxItem ci-full">
              <span class="itemLabel">班级状态:</span>
              <el-select
                v-model="projectState"
                placeholder="请选择班级状态"
                clearable
                size="small"
              >
                <el-option
                  v-for="item in ClassstatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
             <div title="负责人:" class="searchboxItem ci-full">
              <span class="itemLabel">负责人:</span>
               <el-input
                v-model="projectUser"
                placeholder="请输入负责人"
                :disabled="disabled"
                size="small"
                clearable
              />
            </div>
          </div>
          <div class="searchbox">
            <div title="开班时间" class="searchboxItem ci-full">
              <span class="itemLabel">开班时间:</span>
              <el-date-picker
                v-model="dateRange"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="small"
              ></el-date-picker>
            </div>
            <div title="结束时间" class="searchboxItem ci-full">
              <span class="itemLabel">结束时间:</span>
              <el-date-picker
                v-model="endTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="small"
                value-format="timestamp"
              ></el-date-picker>
            </div>
            <div class="df" style="padding-right: 15px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="getExport()"
                >导出</el-button
              >
            </div>
            <el-button class="bgc-bv" round @click="customOpen" size="small"
              >自定义表头</el-button
            >
          </div>
        </div>
        <div style="padding: 10px 10px">
          <span>合计开班总数：{{ countNum || 0 }}个</span>
          <span style="padding-left: 30px"
            >合计培训学员总数：{{ countPeopleNum || 0 }}人次</span
          >
          <span style="padding-left: 30px"
            >已结业学员总数：{{ graduationTotalNum || 0 }}人次</span
          >
        </div>
        <div class="framePage-scroll">
          <!-- <div class="ovy-a"> -->
          <el-table
            ref="multipleTable"
            :data="tableData"
            :height="tableHeight"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeader"
            stripe
            v-if="showtable"
          >
            <el-table-column
              label="序号"
              align="center"
              type="index"
              fixed
              :index="indexMethod"
            />
            <el-table-column
              label="班级名称"
              align="left"
              show-overflow-tooltip
              prop="projectName"
              min-width="250px"
              fixed
            />
            <el-table-column
              label="班级编码"
              align="left"
              show-overflow-tooltip
              prop="projectCode"
              min-width="200px"
            />

            <!-- <el-table-column
              label="行政区划"
              align="left"
              show-overflow-tooltip
              prop="areaNamePath"
              width="150px"
            />
            <el-table-column
              label="培训类型"
              align="left"
              show-overflow-tooltip
              prop="trainTypeNamePath"
              width="150"
            />
            <el-table-column
              label="单位名称"
              align="left"
              show-overflow-tooltip
              prop="compName"
              width="200px"
            />
            <el-table-column
              label="负责人"
              align="left"
              show-overflow-tooltip
              prop="projectUser"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.projectUser }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="学员总数"
              align="right"
              show-overflow-tooltip
              prop="projectPeople"
              minWidth="70"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.projectPeople }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="已认证人数"
              align="right"
              show-overflow-tooltip
              prop="authenticNum"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.authenticNum }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="学习人数"
              align="right"
              show-overflow-tooltip
              prop="authenticNum"
              minWidth="70"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.projectStudyNum }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="已完成学习人数"
              align="right"
              show-overflow-tooltip
              prop="proCompleteNum"
              minWidth="110"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.proCompleteNum }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="认证率"
              align="right"
              show-overflow-tooltip
              prop="authenticRate"
              minWidth="60"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.authenticRate + "%" }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="学习完成率"
              align="right"
              show-overflow-tooltip
              prop="projectStudyRate"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.projectStudyRate + "%" }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="学习率"
              align="right"
              show-overflow-tooltip
              prop="authenticRate"
              minWidth="60"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.studyRate + "%" }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="班级状态"
              align="left"
              show-overflow-tooltip
              prop="projectState"
              minWidth="70"
            >
              <template slot-scope="scope">
                <span>
                  {{ $setDictionary("PROJECTSTATE", scope.row.projectState) }}
                </span>
              </template>
            </el-table-column> -->
            <template v-if="tableHaed.length">
              <el-table-column
                v-for="(el, index) in tableHaed"
                :key="index"
                :label="el.label"
                :align="el.textAlign"
                show-overflow-tooltip
                :min-width="el.width"
              >
                <template slot-scope="scope"
                  >{{ getValue(el, scope.row) }}
                  {{
                    el.propName == "authenticRate" ||
                    el.propName == "projectStudyRate" ||
                    el.propName == "studyRate"
                      ? "%"
                      : ""
                  }}</template
                >
              </el-table-column>
            </template>
            <el-table-column
              label="操作"
              width="260px"
              fixed="right"
              align="left"
            >
              <div slot-scope="scope">
                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  @click="showDocsDialog(scope.row)"
                  >下载培训档案</el-button
                >
                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  @click="lookClass(scope.row.projectId)"
                  >班级详情</el-button
                >

                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  @click="
                    screen(scope.row.projectId, scope.row.projectName, 'screen')
                  "
                  >截屏备案</el-button
                >
                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  @click="studyRecords(scope.row.projectId)"
                  >学员学习记录</el-button
                >
                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  @click="toRegulatoryRollCall(scope.row)"
                  v-if="scope.row.projectSource == '30'"
                  >监管点名</el-button
                >
                <el-button
                  style="padding: 0 5px"
                  type="text"
                  size="mini"
                  v-if="
                    scope.row.trainTypeId == '4' ||
                    scope.row.trainTypeId == '5' ||
                    scope.row.trainTypeId == '15' ||
                     scope.row.trainTypeId == '38'
                  "
                  @click="open(scope.row.projectId, scope.row.userId)"
                  >班级抽查</el-button
                >
                <el-button
                  style="padding: 0 5px"
                  type="text"
                  size="mini"
                  v-if="
                    scope.row.projectSource == '80'
                  "
                  @click="open2(scope.row)"
                  >学员档案</el-button
                >
                <el-button
                  style="padding: 0 5px"
                  type="text"
                  size="mini"
                   v-if="
                    scope.row.projectSource == '80'
                  "
                  @click="open3(scope.row)"
                  >机构资料</el-button
                >
              </div>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
          <!-- </div> -->
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="自定义表头"
      :visible.sync="tableHeadDialog"
      width="30%"
      class="custom"
      @close="cencal"
    >
      <p style="padding-left: 20px; margin-bottom: 10px">
        勾选需要显示在列表上的项，可拖动调整顺序
      </p>
      <div>
        <el-tree
          ref="headTree"
          :data="treeDataCopy"
          default-expand-all
          draggable
          :allow-drop="allowDrop"
          :allow-drag="allowDrag"
        >
          <template slot-scope="{ node, data }">
            <el-checkbox
              @change="
                (val) => {
                  handleCheckChange(val, data);
                }
              "
              v-model="data.checked"
              >{{ node.label }}</el-checkbox
            >
          </template>
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cencal">取 消</el-button>
        <el-button class="bgc-bv" @click="resetHeadOk">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="培训档案选择"
      :visible.sync="docsDialogVisible"
      width="50%"
      top="5%"
      :center="true"
      :before-close="closeDocsDialog"
    >
      <div >
        <div class="ovy-a">
          <div class="downloadStyle">
            <!-- 新增的 -->
            <el-form
              ref="docsFormnew"
              :model="docsFormnew"
              class="docsDialog"
              v-show="danganList.length"
            >
              <el-form-item v-for="(item, index) in danganList" :key="index">
                <el-checkbox v-model="docsFormnew[item.dictCode]">{{
                  item.dictValue
                }}</el-checkbox>
              </el-form-item>
            </el-form>
          </div>
          <el-checkbox v-model="agreeState" style="margin: 0 3%">
            <span style="color: red !important">
              根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，
              不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。我已阅读并同意上述内容。
            </span>
          </el-checkbox>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <div>
          <el-button class="bgc-bv" @click="confirmDocsDialog">确定</el-button>
          <el-button @click="closeDocsDialog">取消</el-button>
        </div>
      </span>
    </el-dialog>
    <el-dialog
      title="班级抽查"
      :visible.sync="dialogCert"
      width="50%"
      center
      :before-close="closeDownloadCert"
    >
      <div id="pdf-cert" style="height: 800px"></div>
    </el-dialog>
  </div>
</template>
<script>
const className = "eduCertService";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import moment from "moment";
import { mapGetters } from "vuex";
import pdf from "pdfobject";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "ClassTrainingStatistics",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  data() {
    return {
      danganList: [], // 档案列表
      docsFormnew: {},

      dialogCert: false,
      areatreeList: [], // 行政区划
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      projectUser:'',
      areaId: "",
      projectCode: "",
      projectName: "",
      dateRange: [],
      endTime: [],
      compName: "", // 单位名称
      copNameState: false,
      projectState: "",
      ClassstatusList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "30",
          label: "已通过",
        },
        {
          value: "50",
          label: "培训中",
        },
        {
          value: "60",
          label: "培训结束",
        },
      ],
      companytype: "",
      entrytime: "",
      ruleForm: {
        Trainingtype: "",
      },
      params: {},
      countNum: "",
      countPeopleNum: "",
      graduationTotalNum:'',
      projectId: "",
      selectedItem: {},
      docsDialogVisible: false,
      agreeState: false, //弹框是否同意
   
      // 自定义表头
      showtable: false,
      tableHeadDialog: false, //
      treeData: [
        {
          label: "行政区划", //表头名称
          propName: "areaNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "150", // 列的宽
          order: 1,
          id: "1",
          checked: true,
        },
        {
          label: "培训类型", //表头名称
          propName: "trainTypeNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "150", // 列的宽
          order: 2,
          id: "2",
          checked: true,
        },
        {
          label: "单位名称", //表头名称
          propName: "compName", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "200", // 列的宽
          order: 3,
          id: "3",
          checked: true,
        },
        {
          label: "负责人", //表头名称
          propName: "projectUser", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "160", // 列的宽
          order: 4,
          id: "4",
          checked: true,
        },
        {
          label: "学员总数", //表头名称
          propName: "projectPeople", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "70", // 列的宽
          order: 5,
          id: "5",
          checked: true,
        },
        {
          label: "已认证人数", //表头名称
          propName: "authenticNum", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "80", // 列的宽
          order: 6,
          id: "6",
          checked: true,
        },
        {
          label: "学习人数", //表头名称
          propName: "projectStudyNum", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "70", // 列的宽
          order: 7,
          id: "7",
          checked: true,
        },
        {
          label: "已完成学习人数", //表头名称
          propName: "proCompleteNum", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "110", // 列的宽
          order: 8,
          id: "8",
          checked: true,
        },
        {
          label: "认证率", //表头名称
          propName: "authenticRate", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 9,
          id: "9",
          checked: true,
        },
        {
          label: "学习完成率", //表头名称
          propName: "projectStudyRate", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "150", // 列的宽
          order: 10,
          id: "10",
          checked: true,
        },
        {
          label: "学习率", //表头名称
          propName: "studyRate", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 11,
          id: "11",
          checked: true,
        },
        {
          label: "班级状态", //表头名称
          propName: "projectState", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "status", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "PROJECTSTATE", //字典对应key
          width: "120", // 列的宽
          order: 12,
          id: "12",
          checked: true,
        },
        {
          label: "已结业学习人数", //表头名称
          propName: "graduationNum", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 12,
          id: "12",
          checked: true,
        },
      ],
      tableHaed: [],
      treeDataCopy: [],
      search: {
        areaId: true,
      }
    };
  },
  created() {},
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (oldVal.path != "/web/ClassTrainingStatisticsall") {
          (this.copNameState = false), (this.compName = "");
          this.areaId = "";
          this.getData(-1);
        } else {
          this.copNameState = true;
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.compId = this.$route.query.compId;
    this.areaId = this.$route.query.areaId;
    this.compName = this.$route.query.compName;
    let day = this.day ? this.day.slice(0, 10) : "";
    let curDay = this.$moment().format("YYYY/MM/DD");
    if (curDay > day) {
      this.dayTure = true;
    }
    if (this.compName) {
      this.copNameState = true;
    }
    this.getTableHeight();
    this.getareatree();
    // 自定义表头
    this.getTableHead();
  },
  methods: {
    /**
     * 自定义表头
     */
    getValue(el, row) {
      // 状态
      if (el.renderType == "status") {
        return this.$setDictionary(el.dictionaries, row[el.propName]);
      } else if (el.renderType == "time") {
        // 时间
        if (el.timeType == "moment") {
          return row[el.propName]
            ? this.$moment(row[el.propName]).format("YYYY-MM-DD HH:mm:ss")
            : "--";
        } else if (el.timeType == "momentDate") {
          return row[el.propName]
            ? this.$moment(row[el.propName]).format("YYYY-MM-DD")
            : "--";
        } else if (el.timeType == "custom") {
          return this.getTime(row[el.propName]);
        } else {
          return row[el.propName];
        }
      } else {
        // 其他
        return row[el.propName] || row[el.propName] === 0
          ? row[el.propName]
          : "--";
      }
    },
    // 自定义确定
    resetHeadOk() {
      let list = [];
      let saveData = [];
      this.treeDataCopy.forEach((el, index) => {
        saveData.push({ id: el.id, order: index, checked: el.checked });
        if (el.checked) {
          list.push(el);
        }
      });
      this.tableHaed = list;
      this.tableHeadDialog = false;
      this.$post("/sys/admin/savePageConfig", {
        configInfo: JSON.stringify(saveData),
        pageName: "ClassTrainingStatistics",
      }).then((res) => {
        // ;
        this.getTableHead();
      });
    },
    customOpen() {
      this.tableHeadDialog = true;
      this.handle();
    },
    cencal() {
      this.tableHeadDialog = false;
    },
    getTableHead() {
      this.$post("/sys/admin/findAdminConfig", {
        pageName: "ClassTrainingStatistics",
      })
        .then((res) => {
          if (res.status == 0) {
            const data = res.data || {};
            let list = [];
            if (data.configInfo && data.configInfo != "") {
              list = JSON.parse(data.configInfo);
            }
            if (list.length) {
              list.map((el) => {
                this.treeData.forEach((it, index) => {
                  if (el.id == it.id) {
                    it.checked = el.checked;
                    it.order = el.order;
                  }
                });
              });
              this.treeData.sort((a, b) => {
                return a.order - b.order;
              });
              this.handle();
            } else {
              this.handle();
            }
          } else {
            this.handle();
            // this.tableHaed = [...this.treeData];
          }
        })
        .catch((err) => {
          this.tableHaed = [...this.treeData];
        });
    },
    handle() {
      this.tableHaed = [];
      this.treeDataCopy = [];
      this.treeData.forEach((el) => {
        this.treeDataCopy.push(Object.assign({}, el));
      });
      this.treeDataCopy.forEach((el) => {
        if (el.checked) {
          this.tableHaed.push(el);
        }
      });
      this.showtable = true;
    },
    // 判断拖拽的位置
    allowDrop(draggingNode, dropNode, type) {
      return type !== "inner";
    },
    // 节点是否可以拖拽
    allowDrag(draggingNode) {
      return true;
    },
    handleCheckChange(val, data) {
      // console.log(data);
    },
    /**
     *  tree
     */
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        dateRange: this.dateRange,
        // endTime: this.endTime,
      };
       if (this.search.areaId) {
        this.areaId = this.$route.query.areaId;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.$route.query.compId) {
        params.compId = this.$route.query.compId;
      }
      if (this.endTime) {
        params.endDateStartLong = this.endTime[0];
        params.endDateEndLong = this.endTime[1];
      }
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.projectState) {
        params.projectState = this.projectState;
      }
      if (this.projectUser) {
        params.projectUser = this.projectUser;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.compName || this.$route.query.compName) {
        params.compName = this.compName || this.$route.query.compName;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/gov/project/projectTrainStatistic",
        params,
        pageNum,
      });
      this.getCount();
    },
    getCount() {
      const params = {
        dateRange: this.dateRange,
        // endTime: this.endTime
        // projectName: this.projectName || "",
      };
      if (this.$route.query.compId) {
        params.compId = this.$route.query.compId;
      }
      if (this.compName || this.$route.query.compName) {
        params.compName = this.compName || this.$route.query.compName;
      }
      if (this.endTime) {
        params.endDateStartLong = this.endTime[0];
        params.endDateEndLong = this.endTime[1];
      }
     if (this.search.areaId) {
        this.areaId = this.$route.query.areaId;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.projectState) {
        params.projectState = this.projectState;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.projectUser) {
        params.projectUser = this.projectUser;
      }
      if (this.compName) {
        params.compName = this.compName;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.$post("/gov/project/auditListSum", params)
        .then((ret) => {
          if (ret.status == "0") {
            this.countNum = ret.data.projectCount;
            this.countPeopleNum = ret.data.projectUserCount;
            this.graduationTotalNum  = ret.data.graduationTotalNum;
          }
        })
        .catch((err) => {
          return;
        });
    },
    getExport() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          const params = {};
          if (this.search.areaId) {
        this.areaId = this.$route.query.areaId;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
          if (this.$route.query.compId) {
            params.compId = this.$route.query.compId;
          }
          if (this.dateRange) {
            params.dateRange = this.dateRange;
          }
          if (this.endTime) {
            params.endDateStartLong = this.endTime[0];
            params.endDateEndLong = this.endTime[1];
          }
          if (this.projectCode) {
            params.projectCode = this.projectCode;
          }
          if (this.projectState) {
            params.projectState = this.projectState;
          }
          if (this.projectName) {
            params.projectName = this.projectName;
          }
          if (this.projectUser) {
            params.projectUser = this.projectUser;
          }
          if (this.compName || this.$route.query.compName) {
            params.compName = this.compName || this.$route.query.compName;
          }
          if (this.params.trainFormId) {
            params.trainTypeId = this.params.trainFormId;
          }
          if (this.params.levelFormId) {
            params.trainLevelId = this.params.levelFormId;
          }
          if (this.params.occFormId) {
            params.occupationId = this.params.occFormId;
          }
          if (this.params.postFormId) {
            params.postId = this.params.postFormId;
          }
          if (this.params.industryFormId) {
            params.industryId = this.params.industryFormId;
          }
          this.$post("/gov/project/exportProjectStatistic", params).then(
            (res) => {
              if (res.status == "0") {
                window.open(res.data.url);
              }
            }
          );
        })
        .catch(() => {});
    },
     clean(val, name) {
      this.areaId = val;
      this.search[name] = false;
    },
    //获取行政区划
    getareatree() {
      this.$post("/gov/area/tree", { areaId: this.$route.query.areaId }).then(
        (ret) => {
          this.areatreeList = ret.data;
        }
      );
    },
    studyRecords(projectId) {
      this.$router.push({
        path: "/web/StudentLearningRecord",
        query: {
          projectId,
          needHold2: true,
        },
      });
    },
    screen(projectId, projectName, stu) {
      this.$router.push({
        path: "/web/StudentLearningScreen",
        query: {
          projectId,
          projectName,
          stu,
          needHold2: false,
        },
      });
    },
    lookClass(projectId) {
      this.$router.push({
        path: "/web/ClassTraingDetails",
        query: {
          projectId,
          needHold2: true,
        },
      });
    },
    toRegulatoryRollCall(row){    
      this.$router.push({
        path: "/web/RegulatoryRollCall",
        query: {
          infoData: JSON.stringify(row),
        },
      });
    },
    //班级抽查
    open(projectId, userId) {
      let parma = {
        projectId: projectId,
        userId: userId,
      };
      this.$post("/biz/project/spotCheckReport", parma).then((res) => {
        if (res.status == 0) {
          this.dialogCert = true;
          this.$nextTick(() => {
            pdf.embed(res.message, "#pdf-cert");
          });
        } else {
          that.$message.warning({ message: res.message, duration: 1500 });
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.25) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 60;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
    closeDocsDialog() {
      this.docsDialogVisible = false;
      this.agreeState = false;
    
      this.$refs["docsFormnew"].resetFields();
      for (let key in this.docsForm) {
        this.docsForm[key] = false;
      }
    },
    showDocsDialog(raw) {
      this.docsDialogVisible = true;
      this.selectedItem.id = raw.projectId;
      this.getList(raw.projectId);
      this.selectedItem.name = raw.projectName;
      this.selectedItem.code = raw.projectCode;
      this.trainTypeId = raw.trainTypeId;
    },
    //
    getList(id) {
      this.$post("/run/project/archives", { projectId: id }).then(
        (res) => {
          this.danganList = res.data;
        }
      );
    },
    confirmDocsDialog() {
      if (!this.agreeState) {
        this.$message({
          type: "warning",
          message: "请先阅读提示内容并同意",
        });
        return;
      }
      let params = {
        fileDTOS: [],
      };
      for (let i in this.docsFormnew) {
        if (this.docsFormnew[i]) {
          params.fileDTOS.push({
            fileType: i,
            fileName:
              this.selectedItem.name +
              "-" +
              this.danganList.find((el) => el.dictCode == i).dictValue,
            className: className,
            param: {
              projectId: this.selectedItem.id,
            },
          });
        }
      }
      if (!params.fileDTOS.length) {
        this.$message.error("请至少选择一项进行打印");
        return;
      }
      // if (!params.fileDTOSAll.length) {
      //   this.$message.error("请至少选择一项进行打印");
      //   return;
      // }

      this.$post("/sys/download/asyncDownloadFile", params).then((re) => {
        if (re.status == "0") {
          let list = re.data;
          for (let item of list) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
          this.closeDocsDialog();
        } else {
          this.$message.error(re.message);
        }
      });
    },
     //跳转
    open2(row) {
      sessionStorage.setItem("projectSourceFace", row.projectSource); //人脸审核准对石家庄班级，不允许补学
      //认证统计
      this.$router.push({
        path: "/web/classTrainingRecordsSee",
        query: {
          projectId: row.projectId,
          projectName: row.projectName,
        },
      });
    },
     //跳转 -- 机构资料
    open3(row) {
      sessionStorage.setItem("projectSourceFace", row.projectSource); //人脸审核准对石家庄班级，不允许补学
      //认证统计
      this.$router.push({
        path: "/web/InstitutionalInformation",
        query: {
          projectId: row.projectId,
          projectName: row.projectName,
        },
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
</style>
